<template>
<v-container>
  <v-slide-y-transition>
    <v-card v-show="show0">
      <v-card-title>Abfallliste</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                label="Abfall"
                filled rounded
                :items="wasteList"
                :filter="customFilter"
                return-object
                v-model="selected.waste"
                clearable
            >
              <template v-slot:selection="data">
                <div>{{ data.item.sn }} {{ data.item.snsub }} {{ data.item.toxic }} {{ data.item.description }}</div>
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.description"></v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.sn }} {{ data.item.snsub }} {{ data.item.toxic }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-col cols="12">
            <v-autocomplete
                v-if="selected.description == null"
                label="Spezifizierung"
                filled rounded
                :items="specList"
                return-object
                v-model="selectedSpec"
                :disabled="selected.description == null"
            >
              <template v-slot:selection="data">
                {{ data.item.sn }} {{ data.item.snsub }} {{ data.item.toxic }} {{ data.item.description }}
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.description"></v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.sn }} {{ data.item.snsub }} {{ data.item.toxic }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col> -->
          <v-col cols="6">
            <v-text-field label="Menge" filled rounded type="number" v-model="selected.quantity" @change="quantityChange"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select label="Einheit" filled rounded :items="units" v-model="selected.unit"></v-select>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn :class="shakeIcon" icon outlined @click="addWaste" color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels multiple>
          <v-expansion-panel
              v-for="(waste, i) in wastes"
              :key="i"
          >
            <v-expansion-panel-header>
              {{ waste.sn }} | {{ waste.snsub }} | {{ waste.toxic }} | {{ waste.description }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="10">
                  {{ waste.quantity }} {{ waste.unit }}
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn icon outlined @click="removeWaste(i)" color="red"><v-icon>mdi-minus</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" @click="showCompanyData" :disabled="wastes.length == 0">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="show1">
      <v-card-title>Meine Daten</v-card-title>
      <v-card-text>
        <v-form
            ref="data"
            v-model="validData"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field label="Firma *" v-model="companyData.company" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Straße *" v-model="companyData.street" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Nummer *" v-model="companyData.streetno" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="PLZ *" v-model="companyData.zip" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Ort *" v-model="companyData.city" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Vorname *" v-model="companyData.firstname" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Nachname *" v-model="companyData.lastname" rounded filled :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox :items="businessroles" v-model="companyData.role" rounded filled></v-combobox>
            </v-col>
            <v-col cols="6">
              <v-text-field label="E-Mail *" v-model="companyData.email" rounded filled :rules="[rules.required, rules.email]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Telefon" v-model="companyData.phone" rounded filled></v-text-field>
            </v-col>
            <v-col>
              <v-switch  v-model="companyData.consentAGBPrivacy" inset :rules="[rules.required]" label="Ich bin mit den allgemeinen Geschäftsbedingungen und der Verarbeitung meiner Daten im Rahmen der Datenschutzerklärung  einverstanden."></v-switch>
              <v-switch v-model="companyData.consentMarketing" inset label="Ich bin damit einverstanden, dass Wastics mich per Email oder telefonisch kontaktiert und mir weitere Angebote zur Verfügung stellt."></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded @click="back">Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" :loading="loading" :disabled="!validData || loading" @click="generatePDF">Erstellen</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="show2">
      <v-card-title>Abfallliste</v-card-title>
      <v-card-text>
        Du kannst deine Abfallliste <a :href="href" target="_blank">downloaden</a>.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showCompanyData">Dashboard</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <!-- <v-text-field label="GLN" v-model="gln" :rules="[rules.gln]"></v-text-field>
  <v-text-field label="Firma" v-model="fname" readonly></v-text-field>
  <v-btn @click="checkGLN">Test</v-btn> -->
  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      style="z-index: 10100;"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "pinia";
import {wasticsStore} from "@/store";

export default {
  name: "Wastelist",
  data: () => ({
    test: null,
    wasteList: [
        "Toxische Biere",
        "Altbiere",
        "Bratwürste",
        "Veganes Zeug"
    ],
    units: [
      "kg", "t", "m³", "l", "Stück"
    ],
    businessroles: [
      "Stellvertretende*r Abfallbeauftragte*r",
      "Abfallrechtliche*r Geschäftsführer*in (gem. § 26 Abs. 1 AWG 2002)",
      "Verantwortliche Person (gem. § 26 Abs. 6 AWG 2002)",
      "Fachkundige Person (gem. § 26 Abs. 4 AWG 2002)",
      "Umweltmanager*in",
      "Qualitätsmanager*in",
      "Gefahrgutbeauftragte*r",
      "Giftbeauftragte*r",
      "Sicherheitsfachkraft",
      "Geschäftsführer*in",
      "Prokurist*in",
      "Handelsrechtliche*r Geschäftsführer*in",
      "Gewerberechtliche*r Geschäftsführer*in",
      "Betreiber*in der Anlage",
      "Betriebsleiter*in",
      "Inhaber*in der Anlage",
    ],
    wastes: [],
    selectedDefault: {
      waste: {
        sn: "",
        snsub: "",
        description: "",
        toxic: "",
      },
      quantity: "",
      unit: "kg",
    },
    selected: null,
    selectedSpec: null,
    show0: true,
    show1: false,
    show2: false,
    validData: false,
    loading: false,
    rules: {
      required: value => !!value || 'Erforderlich',
      counter: value => value.length <= 20 || 'Maximal 20 Zeichen',
      minLength: value => value.length > 7 || 'Mindestens 8 Zeichen',
      UN: value => value.length == 4 || 'Genau 4 Ziffern',
      zip: value => value.length <= 5 || 'PLZ maximal 5-stellig',
      zip2: value => {
        const pattern = /^[0-9]{4,5}$/
        return pattern.test(value) || 'PLZ darf nur 4-5 Ziffern enthalten'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email-Adresse erforderlich'
      },
      max500: value => value.length <= 500 || 'Max. 500 Zeichen',
      max4: v => v.length <= 4 || 'Max. 4 Ziffern',
      gln: v => v.length == 13 || '13-stellige GLN',
    },
    companyData: {
      company: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      role: "",
      consentAGBPrivacy: false,
      consentMarketing: false,
    },
    href: "",
    snackbar: false,
    snackbarColor: "red",
    message: "",
    gln: "9005914000000",
    fname: "",

    shakeIcon: "mt-3",
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    specList() {
      if(this.selected.description !== null) {
        return this.selected.description.subsn
      }
      return null;
    },
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    addWaste() {
      let w = {
        sn: this.selected.waste.sn,
        snsub: this.selected.waste.snsub,
        description: this.selected.waste.description,
        toxic: this.selected.waste.toxic,
        quantity: this.selected.quantity,
        unit: this.selected.unit,
      };
      this.wastes.push(w);
      this.selected = Object.assign({}, this.selectedDefault);
    },
    removeWaste(i) {
      console.log(i);
      this.wastes.splice(i, 1);
    },
    showCompanyData() {
      this.show0 = false;
      this.show2 = false;
      setTimeout(()=>{
        this.show1 = true;
      }, 500);
    },
    showSuccess() {
      this.show1 = false;
      setTimeout(()=>{
        this.show2 = true;
      }, 500);
    },
    back() {
      this.show1 = false;
      setTimeout(()=>{
        this.show0 = true;
      }, 500);
    },
    generatePDF() {
      this.loading = true;
      let data = {
        company: this.companyData,
        wastes: this.wastes,
      };
      axios.post(this.baseUrl + "/v1/wastelist", data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}, responseType: 'blob'})
      .then((res)=>{
        console.log(res)
        let blob = new Blob([res.data], { type: res.headers['content-type'] });
        this.href = window.URL.createObjectURL(blob);
        this.loading = false;
        this.showSuccess();
      })
      .catch((err)=>{
        console.log(err);
        this.loading = false;
        this.message = "Beim erstellen der Abfallliste ist ein Fehler aufgetreten."
        this.snackbarColor = "red";
        this.snackbar = true;
      });
    },
    // eslint-disable-next-line no-unused-vars
    customFilter (item, queryText, itemText) {
      const sn = item.sn.toLowerCase()
      const description = item.description.toLowerCase()
      const searchText = queryText.toLowerCase()

      return sn.indexOf(searchText) > -1 ||
          description.indexOf(searchText) > -1
    },
    checkGLN() {
      axios.post(this.baseUrl + "/v1/checkGLN", {gln: this.gln}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then(res => {console.log(res.data); this.fname = res.data.name})
      .catch(err => {console.log(err)});
    },
    quantityChange() {
      this.shakeIcon = "mt-3 shakeit";
      setTimeout(()=>{this.shakeIcon = "mt-3"}, 2000);
    },
  },
  created() {
    this.selected = Object.assign({}, this.selectedDefault);
    axios.get(this.baseUrl + "/v1/wasteindex", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
        .then(res => {
          console.log(res.data);
          this.wasteList = res.data;
        })
        .catch(err => {console.log(err)});
  }
}
</script>

<style scoped>

.shakeit {
  animation: grow 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes grow {
  0% {transform: scale(1.0);}
  50% {transform: scale(1.15);}
  100% {transform: scale(1.0);}
}
</style>