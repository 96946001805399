<template>
  <v-app>
    <v-navigation-drawer
        app
        v-model="drawer"
        style="z-index: 10000;"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="/img/avatar-nature.jpg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content v-if="loggedIn">
            <v-list-item-title class="title">
              {{user.firstname}} {{user.lastname}}
            </v-list-item-title>
            <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title class="title">
              Konto erstellen
            </v-list-item-title>
            <v-list-item-subtitle>Einfach registrieren</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- hide group if !user.registrationFinished -->
        <v-list-item-group
            color="primary"
        >
          <v-list-item to="/edm" @click="trackNavigation('edm')">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>EDM</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/" v-if="user.registrationFinished" @click="trackNavigation('dashboard')">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/abfallliste" @click="trackNavigation('abfallliste')" v-if="user.registrationFinished">
            <v-list-item-icon><v-icon>mdi-view-list</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abfallliste</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/welcome" @click="trackNavigation('welcome')" v-if="!user.registrationFinished">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Willkommen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/entsorgen" @click="trackNavigation('entsorgen')" v-if="user.registrationFinished">
            <v-list-item-icon><v-icon>mdi-truck-fast</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Entsorgen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/easy-entsorgen" @click="trackNavigation('easy-entsorgen')" v-if="user.registrationFinished">
            <v-list-item-icon><v-icon>mdi-truck-fast</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Entsorgen (einfach)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/registration" v-if="!loggedIn">
            <v-list-item-icon><v-icon>mdi-account-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Registrieren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- waste creator role menu entries -->
          <v-list-item to="/anfragen" v-if="loggedIn && user.role.startsWith('waste_creator') && user.registrationFinished">
            <v-list-item-icon><v-icon>mdi-text-box-search-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Anfragen
                <v-badge color="pink" dot></v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              :value="false"
              prepend-icon="mdi-format-list-text"
              no-action
              v-if="loggedIn && user.role.startsWith('waste_creator')"
          >
            <template v-slot:activator>
              <v-list-item-title>Aufträge</v-list-item-title>
            </template>
            <v-list-item
                sub-group
                color="grey"
                link
                to="/orders"
                @click="trackNavigation('auftragsverwaltung')"
            >
              <template>
                <v-list-item-content>
                  <v-list-item-title>Auftragsverwaltung</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item
                sub-group
                color="grey"
                link
                to="/rV"
                @click="trackNavigation('rechnungsverwaltung')"
            >
              <template>
                <v-list-item-content>
                  <v-list-item-title>Rechnungsverwaltung</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-group>
          <!--
          <v-list-item to="/orders" v-if="loggedIn && payload.role.startsWith('waste_creator')">
            <v-list-item-icon><v-icon>mdi-format-list-text</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Aufträge</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/invoices" v-if="loggedIn && payload.role.startsWith('waste_creator')">
            <v-list-item-icon><v-icon>mdi-format-list-text</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Rechnungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->
          <v-list-item to="/wastemanagement" v-if="loggedIn && user.role.startsWith('waste_creator')">
            <v-list-item-icon><v-icon>mdi-chart-timeline-variant-shimmer</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abfallmanagement</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- wastics management menu entries -->
          <v-list-item to="/order-manager" @click="trackNavigation('wastics-manager-auftragsverwaltung')" v-if="loggedIn && user.role === 'manager'">
            <v-list-item-icon><v-icon>mdi-format-list-text</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Auftragsverwaltung</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/users" @click="trackNavigation('wastics-manager-benutzerverwaltung')" v-if="loggedIn && user.role === 'manager'">
            <v-list-item-icon><v-icon>mdi-account-multiple</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Benutzerverwaltung</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- general user menu entries -->
          <v-list-group
              :value="false"
              prepend-icon="mdi-city"
              no-action
              v-if="loggedIn && user.registrationFinished"
          >
            <template v-slot:activator>
              <v-list-item-title>Stammdaten</v-list-item-title>
            </template>
            <v-list-item
                sub-group
                color="grey"
                link
                to="/profile"
                @click="trackNavigation('benutzerprofil')"
            >
              <v-list-item-content>
                <v-list-item-title>Benutzer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                sub-group
                color="grey"
                link
                to="/company"
                @click="trackNavigation('firma')"
            >
              <v-list-item-content>
                <v-list-item-title>Firma</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                sub-group
                color="grey"
                link
                to="/locations"
                @click="trackNavigation('standorte')"
            >
              <v-list-item-content>
                <v-list-item-title>Standorte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- basic login | logout menu entries -->
          <!--<v-list-item @click="logout" v-if="loggedIn">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/login" v-else>
            <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!--<v-list-item @click="showWebsite">
            <v-list-item-content>
              <v-list-item-title>Website</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
          <!--<v-list-item @click="getSecretContent" v-if="isAuth">
            <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Secret Content</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item
              v-if="keycloak.realmAccess.roles.includes('wastics_admin')"
              to="/cms"
          >
            <v-list-item-icon><v-icon>mdi-web</v-icon></v-list-item-icon>
            <v-list-item-content>CMS</v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteNonfunctionalCookies">
            <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
            <v-list-item-content>Cookies löschen</v-list-item-content>
          </v-list-item>
          <v-list-item @click="logoutKeycloak" v-if="isAuth">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        elevate-on-scroll
        color="white"
        style="z-index: 9999;"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-hover>
        <v-img v-if="loggedIn" class="elevation" src="https://wastics.eu/assets/images/wastics_symbol_vektor.svg" max-height="40" max-width="40" contain v-on:click="goToHome" style="cursor: pointer; left: -25px;"/>
        <v-img v-else class="elevation" src="https://wastics.eu/assets/images/wastics_symbol_vektor.svg" max-height="40" max-width="40" contain style="left: -25px;" />
      </v-hover>
      <v-spacer></v-spacer>
      <!--<v-btn icon to="/orders"><v-icon>mdi-format-list-text</v-icon></v-btn>-->
    </v-app-bar>

    <v-main>
      <!--<v-alert class="mx-5" prominent border="left" color="pink" type="info" v-if="user.business == null" to="/company">
        <v-row align="center">
          <v-col class="grow">
            Bitte Firmendaten ergänzen
          </v-col>
          <v-col class="shrink">
            <v-btn to="/company">Hier klicken</v-btn>
          </v-col>
        </v-row>
      </v-alert>-->
      <v-slide-x-transition mode="out-in">
        <router-view/>
      </v-slide-x-transition>
    </v-main>
    <div id="footer">
      <v-container class="mt-5">
        <v-sheet>
          <v-row>
            <v-col cols="12" sm="4">
              <h2>Adresse</h2>
              <span>Wastics GmbH</span><br><span>Vivenotgasse 52/42</span><br><span>1120 Wien</span>
            </v-col>
            <v-col cols="12" sm="4">
              <h2>Kontakt</h2>
              <span>hello@wastics.eu</span><br><a href="tel:+4319346231">+43 1 9346231</a>
            </v-col>
            <v-col cols="12" sm="4">
              <h2>Weitere Informationen</h2>
              <ul>
                <li><a href="/agb">AGB</a></li>
                <li><a href="https://wastics.eu/impressum">Impressum</a></li>
                <li><a href="https://wastics.eu/datenschutz">Datenschutzerklärung</a></li>
              </ul>
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </div>
    <v-dialog v-model="cookieConsentDialog" class="justify-center" width="80vh" max-width="600">
      <v-card  class="justify-center">
        <v-card-title><v-icon class="mr-2">mdi-cookie</v-icon> Wir verwenden Cookies</v-card-title>
        <v-card-text>
          <div class="mb-5">
            Wir verwenden Cookies, um unseren Service zu optimieren (<a href="https://wastics.eu/datenschutz" target="_blank">Datenschutzerklärung</a> - <a href="https://wastics.eu/impressum" target="_blank">Impressum</a>)
          </div>
          <v-row class="text-center">
            <v-col cols="12" sm="6">
              <v-btn color="primary" x-large @click="setCookieConsent('all')">Alle aktzeptieren</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="grey darken-1" text @click="setCookieConsent('functional')">Nur funktionale Cookies</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<style scoped>
#footer {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 50px;
}
#footer, #footer * {
  background-color: #320051;
  color: #ffffff;
}
#footer a {
  color: #ffffff;
  text-decoration: none;
}
#footer ul {
  list-style: none;
  list-style-position: outside;
  padding: 0;
  padding-inline-start: 0;
}
#footer li {
  text-align: left;
  padding: 0;
}
</style>
<script>
import Vue from 'vue';
import {mapActions, mapState} from "pinia";
import {wasticsStore} from "./store";
import axios from "axios";

export default Vue.extend({
  name: 'App',
  data: () => ({
    drawer: false,
    cookieConsentDialog: false,
  }),
  computed: {
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["token"]),
    ...mapState(wasticsStore, ["payload"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  watch: {
    user(newVal, oldVal) {
      console.log(oldVal);
      console.log(newVal);
      if(newVal.email !== '') {
        if(newVal.registrationFinished === false) {
          this.$router.push('/welcome');
        }
      }
    },
  },
  methods: {
    trackNavigation(item) {
      window.umami.trackEvent('navigation-' + item, {}, window.location.pathname, '959954a6-dbb5-4f79-9dbc-e699c28decd1')
    },
    logout() {
      this.clearToken();
      this.$router.push("/");
    },
    goToHome(){
      this.$router.push('/');
    },
    // TODO delete method
    getSecretContent() {
      axios.get("http://localhost:30303/test1234").then(()=>{console.log("resource fetched")}).catch((err)=>{console.log(err);});
      axios.get("http://localhost:30303/v1/users/" + this.keycloak.subject, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then((res)=>{
        console.log(res.data)
      })
      .catch((error)=>{
        console.log(error)
      });
    },
    isAuth() {
      if(this.keycloak) {
        return this.keycloak.authenticated;
      }
      return false;
    },
    loginKeycloak() {
      if(this.keycloak) {
        this.keycloak.login();
      }
    },
    logoutKeycloak() {
      if(this.keycloak) {
        this.keycloak.logout();
      }
    },
    showWebsite() {
      window.open("https://wastics.eu", "_blank").focus();
    },
    checkCookieConsent() {
      if(localStorage.getItem("wastics.cookieConsent") == null) {
        this.cookieConsentDialog = true;
      }
      if(localStorage.getItem("wastics.cookieConsent") === 'all') {
        this.enableAllCookies();
      } else {
        this.deleteNonfunctionalCookies();
      }
    },
    /* valid options are 'all' or 'functional' */
    setCookieConsent(option) {
      localStorage.setItem("wastics.cookieConsent", option);
      this.cookieConsentDialog = false;
    },
    enableAllCookies() {
      let test = document.createElement('script');
      test.type = "text/javascript";
      test.text = "alert('hello from test')";

      //document.head.appendChild(test);

      let gaScript0 = document.createElement('script');
      gaScript0.defer = true;
      gaScript0.src = "https://www.googletagmanager.com/gtag/js?id=G-33L0FKY778";

      let gaScript1 = document.createElement('script');
      gaScript1.type = "text/javascript";
      gaScript1.text = "window.dataLayer = window.dataLayer || [];\n" +
          "\n" +
          "        function gtag() {\n" +
          "            dataLayer.push(arguments);\n" +
          "        }\n" +
          "\n" +
          "        gtag('js', new Date());\n" +
          "        gtag('config', 'G-33L0FKY778');";

      gaScript0.onload = function () {
        if(window.location.hostname !== "localhost") {
          document.head.appendChild(gaScript1);
        }
      };

      let linkedInScript0 = document.createElement('script');
      linkedInScript0.type = "text/javascript";
      linkedInScript0.text = "_linkedin_partner_id = \"3795234\";\n" +
          "        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];\n" +
          "        window._linkedin_data_partner_ids.push(_linkedin_partner_id);";

      let linkedInScript1 = document.createElement('script');
      linkedInScript1.type = "text/javascript";
      linkedInScript1.text = "(function (l) {\n" +
          "            if (!l) {\n" +
          "                window.lintrk = function (a, b) {\n" +
          "                    window.lintrk.q.push([a, b])\n" +
          "                };\n" +
          "                window.lintrk.q = []\n" +
          "            }\n" +
          "            let s = document.getElementsByTagName(\"script\")[0];\n" +
          "            let b = document.createElement(\"script\");\n" +
          "            b.type = \"text/javascript\";\n" +
          "            b.async = true;\n" +
          "            b.src = \"https://snap.licdn.com/li.lms-analytics/insight.min.js\";\n" +
          "            s.parentNode.insertBefore(b, s);\n" +
          "        })(window.lintrk);";

      if(window.location.hostname !== "localhost") {
        document.head.appendChild(gaScript0);
        //document.head.appendChild(linkedInScript0);
        //document.head.appendChild(linkedInScript1);
      }
    },
    deleteNonfunctionalCookies() {
      this.deleteCookie("s_ppv", ".linkedin.com");
      this.deleteCookie("s_cc", ".linkedin.com");
      this.deleteCookie("s_tp", ".linkedin.com");
      this.deleteCookie("s_plt", ".linkedin.com");
      this.deleteCookie("li_mc", ".linkedin.com");
      this.deleteCookie("lms_ads", ".linkedin.com");
      this.deleteCookie("s_pltp", ".linkedin.com");
      this.deleteCookie("aam_uuid", ".linkedin.com");
      this.deleteCookie("liap", ".linkedin.com");
      console.log(document.cookie);
    },
    deleteCookie(name, domain) {
      console.log("Delete cooke " + name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;  Domain=' + domain);
      document.cookie = name +'=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;  domain=' + domain + ";";
    },
    ...mapActions(wasticsStore, ["clearToken"]),
    ...mapActions(wasticsStore, ["checkToken"]),
  },
  mounted() {
    this.checkToken();
    setTimeout(()=>{
      window.umami.trackView(window.location.pathname, document.referrer, '959954a6-dbb5-4f79-9dbc-e699c28decd1')
      console.log("trackView")
    }, 300);
    console.log("Version 1.0.221203")
  },
  created() {
    console.log(document.cookie);
    this.checkCookieConsent();
    /* setTimeout(()=> {
      this.getSecretContent()
    }, 3000); */
  }
});
</script>
