<template>
<v-container>
  <v-card v-if="user.role == 'manager'">
    <v-data-table
        :headers="headers"
        :items="users"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title @click.meta.shift="setAdmin">Benutzerverwaltung</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="editUserDialog"
              fullscreen
              style="z-index: 10005;"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  rounded
                  disabled
              >
                Neuer Benutzer
              </v-btn>
            </template>
            <v-card>
              <v-card-title>{{editUserDialogTitle}}</v-card-title>
              <v-card-text>
                <v-form
                    ref="form"
                    v-model="userFormValid"
                >
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          label="Vorname"
                          v-model="editedUser.firstname"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          label="Nachname"
                          v-model="editedUser.lastname"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          label="Email"
                          v-model="editedUser.email"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          label="Telefon"
                          v-model="editedUser.phone"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          label="Passwort"
                          v-model="editedUser.password"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-select
                          label="Rolle"
                          :items="roles"
                          v-model="editedUser.role"
                          outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-switch
                        label="Registrierung abgeschlossen"
                        v-model="editedUser.registrationFinished"
                        inset
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeEditUserDialog" rounded>Abbrechen</v-btn>
                <v-btn @click="saveUser(editedUser)" color="primary" rounded>Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.company="{ item }">
        {{ lookupBusiness( item.business ) }}
      </template>
      <template v-slot:item.last_active="{ item }">
        {{item.last_active | simpleDate}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editUser(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteUser(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
  <v-card class="mt-5" v-if="user.role == 'manager'">
    <v-toolbar flat>
      <v-toolbar-title>Firmenverwaltung</v-toolbar-title>
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog
          v-model="editBusinessDialog"
          fullscreen
          style="z-index: 10005;"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              rounded
              disabled
          >
            Neue Firma
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Firma bearbeiten</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Name" v-model="editedBusiness.name" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field label="Straße" v-model="editedBusiness.street" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field label="Nummer" v-model="editedBusiness.streetNo" outlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Adresse (optional)" v-model="editedBusiness.additional" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field label="PLZ" v-model="editedBusiness.zip" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field label="Ort" v-model="editedBusiness.city" outlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select label="Land" v-model="editedBusiness.country" outlined :items="countries"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Telefon" v-model="editedBusiness.phone" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="E-Mail" v-model="editedBusiness.mail" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Firmenbuch" v-model="editedBusiness.fn" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="UID" v-model="editedBusiness.uid" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="GLN" v-model="editedBusiness.gln" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Webseite" v-model="editedBusiness.url" outlined></v-text-field>
              </v-col>
              <v-col>
                <v-select
                    v-model="editedBusiness.types"
                    :items="businessTypes"
                    label="Art"
                    outlined
                    multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeEditBusinessDialog">Abbrechen</v-btn>
            <v-btn @click="updateCompany" color="primary">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
        :headers="headersBusiness"
        :items="businesses"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editBusiness(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteBusiness(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      style="z-index: 10007;"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "pinia";
import {wasticsStore} from "@/store";

export default {
  name: "UserManager",
  data: () => ({
    headers: [
      {text: "Nachname", value: "lastname"},
      {text: "Vorname", value: "firstname"},
      {text: "Email", value: "email"},
      {text: "Firma", value: "company", sortable: false},
      {text: "Rolle", value: "role"},
      {text: "Zuletzt online", value: "last_active"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],
    defaultUser: {
      id: '',
      businessModel: '',
      business: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      role: '',
    },
    editedUser: {},
    editUserDialog: false,
    editUserDialogTitle: "Benutzer anlegen",
    userFormValid: false,
    users: [],
    businesses: [],
    businessMap: null,
    headersBusiness: [
      {text: "Firma", value: "name"},
      {text: "UID", value: "vatCode"},
      {text: "Art", value: "type"},
      {text: "Arten", value: "types"},
      {text: "Aktionen", value: "actions", sortable: false},
    ],
    roles: [
        'waste_creator',
        'waste_creator_disposer',
        'waste_disposer',
        'manager',
    ],
    businessTypes: [
        "WASTE_PRODUCER",
        "WASTE_COLLECTOR",
        "WASTE_DISPOSAL",
        "WASTE_CONSULTANT",
        "CONVERTER",
        "SUPPLIER",
        "TRANSPORT",
    ],
    defaultBusiness: {
      id: "",
      gln: "",
      uid: "",
      fn: "",
      name: "",
      street: "",
      streetNo: "",
      door: "",
      additional: "",
      zip: "",
      city: "",
      country: "Österreich",
      phone: "+43",
      url: "https://",
      type: "",
      types: [],
    },
    editedBusiness: {},
    editBusinessDialog: false,
    snackbar: false,
    snackbarColor: "red",
    message: '',
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    closeEditUserDialog() {
      this.editedUser = Object.assign({}, this.defaultUser);
      this.editUserDialogTitle = "Benutzer anlegen";
      this.editUserDialog = false;
    },
    getBusinesses() {
      axios
          .get(this.baseUrl + "/v1/businesses", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.businesses = res.data;
            this.businessMap = new Map(this.businesses.map(i => [i.id, i]));
            console.log(this.businessMap);
            console.log("###########");
            console.log(this.businessMap.get("01ed5f6d-daba-1c2a-9003-cd8ab994eb37"));
            this.getUsers();
          })
          .catch((err) => {console.log(err)})
    },
    getUsers() {
      axios
          .get(this.baseUrl + "/v1/users", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.users = res.data;
          })
          .catch((err) => {console.log(err)})
    },
    deleteUser(user) {
      axios
          .delete(this.baseUrl + "/v1/users/" + user.id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(() => {
            this.getUsers();
          })
          .catch((err) => {console.log(err)})
    },
    editUser(user) {
      this.editedUser = Object.assign({}, user);
      this.editUserDialogTitle = "Benutzer bearbeiten";
      this.editUserDialog = true;
    },
    saveUser(user) {
      axios
          .put(this.baseUrl + "/v1/users", user, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(() => {
            this.getUsers();
            this.closeEditUserDialog();
          })
          .catch((err) => {
            console.log(err);
            this.message = "Fehler beim Speichern";
            this.snackbar = true;
          })
    },
    lookupBusiness(id) {
      if(id && id.length > 1) {
        let bu = this.businessMap.get(id)
        if(bu) {
          return bu.name;
        } else {
          return "nicht zugewiesen";
        }
      }
      return "Nicht zugewiesen";
    },
    editBusiness(business) {
      console.log(business);
      this.editedBusiness = Object.assign({}, business);
      this.editBusinessDialog = true;
    },
    closeEditBusinessDialog() {
      this.editedBusiness = Object.assign({}, this.defaultBusiness);
      this.editBusinessDialog = false;
    },
    deleteBusiness(business) {
      this.message = "Nicht unterstützt - " + business.name;
      this.snackbar = true;
    },
    updateCompany() {
      axios.put(this.baseUrl + "/v1/companies", this.editedBusiness, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            this.message = "Daten aktualisiert";
            this.snackbarColor = "green";
            this.company = res.data;
            this.snackbar = true;
            this.getBusinesses();
            this.closeEditBusinessDialog();
          })
          .catch(()=>{
            this.message = "Daten konnten nicht aktualisiert werden";
            this.snackbarColor = "red";
            this.snackbar = true;
          });
    },
  },
  created() {
    this.getBusinesses();
    this.editedUser = Object.assign({}, this.defaultUser);
    this.editedBusiness = Object.assign({}, this.defaultBusiness);
  },
  filters: {
    simpleDate(value) {
      if (!value) return ''
      value = value.toString()
      let date = new Date(value)
      return new Intl.DateTimeFormat('de-DE', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date)
    },
  },
}
</script>

<style scoped>

</style>