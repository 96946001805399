<template>
<v-container style="max-width: 800px;">
  <h1>Allgemeine Geschäftsbedingungen</h1>
  <h2>Allgemeines und Anwendungsbereich</h2>
  <ol>
    <li>
      Die nachstehenden Allgemeinen Geschäftsbedingungen (im Folgenden auch: „AGB“) gelten ausschließlich für das durch die Wastics GmbH, Vivenotgasse 52/2/42, 1120 Wien (im Folgenden auch: „Betreiber“ oder „wir/uns“) betriebene Portal wastics.eu (im Folgenden auch: „Portal“) und dem Interessenten (Nutzer) abgeschlossenen Verträge wie auch für sämtliche der von uns zur Verfügung gestellten Leistungen, speziell die Beratung. Von diesen Allgemeinen Geschäftsbedingungen abweichende Bedingungen des Nutzers gelten nicht.
    </li>
    <li>
      Die Angebote auf dem Portal richten sich an juristische Personen und an Verbraucher, die das 18. Lebensjahr vollendet haben und unbeschränkt geschäftsfähig sind. Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, das überwiegend weder ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.
    </li>
    <li>
      Die Vertragssprache ist Deutsch. Die Geschäftsbeziehungen zwischen uns und dem Nutzer unterliegen dem Recht der Bundesrepublik Österreich unter Ausschluss des UN-Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.
    </li>
    <li>
      Verbraucher haben die Möglichkeit eine alternative Streitbeilegung zu nutzen. Der folgende Link der EU-Kommission (auch OS-Plattform genannt) enthält Informationen über die Online-Streitschlichtung und dient als zentrale Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen: https://ec.europa.eu/consumers/odr. Zu einer Teilnahme an einem Schlichtungsverfahren sind wir weder bereit noch verpflichtet.
    </li>
    <li>
      Informationspflicht gem. Verbraucherstreitbeilegungsgesetz: Wir sind nicht zur Teilnahme an weiteren Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle bereit und/oder verpflichtet.
    </li>
    <li>
      Diese AGB können in der jeweils aktuellen Version jederzeit auf dem Portal abgerufen werden.
    </li>
  </ol>
  <h2>Leistungen des Betreibers</h2>
  <ol>
    <li>
      Der Betreiber stellt auf dem Portal allgemeine Informationen zum Thema kostenlos zur Verfügung, die sich nicht konkret auf den Verkauf von Dienstleistungen beziehen. Die Informationen dienen lediglich der Entscheidungsfindung über das „Ob“ und das „Wie“ des Vorhabens.
    </li>
    <li>
      Der Betreiber stellt den Nutzern, die sich registrieren, von Zeit zu Zeit Informationen zum Thema und sonstige dazu nahe Themen zur Verfügung.
    </li>
    <li>
      Der Betreiber vermittelt schließlich für die registrierten und nicht registrierten Nutzer kostenlos und unverbindlich zur Anfrage passende Fachunternehmen, welche zunächst beraten, ein Angebot erstellen und die Dienstleistung durchführen können.
    </li>
  </ol>
  <h2>Vertragsschluss</h2>
  <ol>
    <li>Der Nutzer hat die Möglichkeit, über das Portal eine Anfrage zur Vermittlung von problemspezifischen Fachfirmen aus dem Wirtschaftsbereich der Entsorgung und kreislaufwirtschaftlichen Beratung zu stellen. Die Anfrage ist für den Nutzer kostenfrei und unverbindlich und stellt noch kein Angebot im Rechtssinne zur Beauftragung einer Dienstleistung dar.</li>
    <li>Mit Absenden der Anfrage bestätigt der Nutzer diese AGB und es kommt zwischen dem Nutzer und dem Betreiber ein für den Nutzer kostenfreier Vermittlungsvertrag nach Maßgabe dieser AGB zu Stande. Mit diesem Vermittlungsvertrag wird der Betreiber beauftragt, je nach Art und Inhalt der Anfrage passende Unternehmen/Dienstleistern zur Seite zu stellen, welche das Problem lösen können.</li>
    <li>Der Betreiber prüft die Anfrage und vermittelt bis zu 3 geeignete Unternehmen/Dienstleister. Die Unternehmen/Dienstleister beraten und übersenden dem Nutzer jeweils ein entsprechendes Angebot. Ein Anspruch des Nutzers auf Vermittlung durch den Betreiber besteht nicht. Auch besteht kein Anspruch auf Beratung und/oder die Zusendung von Angeboten durch die vermittelten Unternehmen/Dienstleister.</li>
    <li>Der Betreiber ist zum Zweck der Vermittlung berechtigt, mit dem Nutzer in Kontakt zu treten (zB. per E-Mail oder telefonisch) und die Daten und Angaben des Nutzers an Unternehmen/Dienstleister weiterzugeben, um Angebote einzuholen. Die Unternehmen/Dienstleister dürfen den Nutzer zum Zwecke der Beratung und Übersendung von Angeboten Kontakt mit dem Nutzer aufnehmen und hierfür die von dem Betreiber mitgeteilten Daten verarbeiten.</li>
    <li>Nach Erhalt der Angebote kann der Nutzer frei entscheiden, ob und wenn ja welches Angebot er annimmt. Nimmt er ein Angebot an, kommt der Vertrag ausschließlich zwischen dem Nutzer und dem jeweiligen Unternehmen/Dienstleister zustande. Innerhalb dieses Vertragsverhältnisses können gesonderte Allgemeine Geschäftsbedingungen und/oder Vertragsbedingungen gelten. Gleiches gilt sofern der Nutzer zusätzliche Leistungen des Unternehmens/Dienstleisters in Anspruch nimmt. Der Betreiber wird nicht Vertragspartner, sondern tritt ausschließlich als Vermittler auf. Der Betreiber ist auch kein Vertreter oder Erfüllungsgehilfe der Unternehmen/Dienstleister.</li>
    <li>Der Betreiber hat auf das Zustandekommen/Nichtzustandekommen eines Auftrages bzw. weitergehende Verträge keinerlei Einfluss. Ob ein Vertrag mit dem Unternehmen/Dienstleister zustande kommt, ist abhängig von den entsprechenden Bedingungen des jeweiligen Unternehmens/Dienstleisters.</li>
    <li>Die Vermittlung durch den Betreiber ist für den Nutzer kostenfrei. Der Betreiber erhält für die Vermittlung eine Provision oder eine Pauschale pro Vermittlung von den Unternehmen/Dienstleistern.</li>
  </ol>
  <h2>Gewährleistung</h2>
  <ol>
    <li>
      Jegliche Informationen über das Thema durch den Betreiber stellen weder eine Beratungsleistung noch eine Verkaufstätigkeit für den Nutzer dar, sondern dienen der Erleichterung einer selbständigen Entscheidung des Nutzers, auf die der Betreiber keinen Einfluss hat. Informationen sind weder als Zusage einer Garantie noch als Zusicherung zu verstehen. Die Leistungen des Betreibers umfassen insbesondere auch keine Rechts- oder Steuerberatung. Der Nutzer ist dazu verpflichtet, den genauen Inhalt des Vertrages mit dem Unternehmen/ Dienstleister zu prüfen.
    </li>
    <li>Der Betreiber übernimmt keine Gewährleistung und/oder Haftung für durch den Betreiber vermittelte Unternehmen/Dienstleister und durch dessen ausgeführte Arbeiten. Für etwaige in dem Vertragsverhältnis zwischen Nutzer und Unternehmen/Dienstleister auftretende Pflichtverletzungen haftet ausschließlich das jeweilige Unternehmenen bzw. der jeweilige Dienstleister.</li>
  </ol>
  <h2>Nutzerpflichten</h2>
  <ol>
    <li>Dem Nutzer ist es untersagt bei der Inanspruchnahme unserer Dienste, die Rechte Dritter zu verletzen oder Dritte sonst zu belästigen. Der Missbrauch oder die Vornahme rechtswidriger Handlungen ist untersagt. Der Nutzer ist verpflichtet, die Nutzung unserer Dienste mit der größtmöglichen Sorgfalt durchzuführen. Insbesondere hat der Nutzer jegliche Handlungen zu unterlassen, die geeignet sind, die Serverintegrität oder -stabilität des Portals unmittelbar oder mittelbar zu beeinträchtigen.</li>
    <li>Die Eingabe und Übermittlung der persönlichen Daten durch den Nutzer erfolgt online über die von dem Betreiber vorbereiteten Eingabemasken oder über andere ausdrücklich von dem Betreiber im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher vom Nutzer in die Eingabemaske eingegebenen oder sonst übermittelten Daten ist ausschließlich der Nutzer selbst verantwortlich. Für den Fall der Übermittlung von vorsätzlich falschen oder unwahren persönlichen Daten durch den Nutzer behält sich der Betreiber vor, ihn von der Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Leistungen auszuschließen und Ersatz für dadurch eingetretene Schäden zu verlangen.</li>
  </ol>
  <h2>Bewertungen/Erlaubnis der Veröffentlichung von Nutzerbewertungen</h2>
  <ol>
    <li>Leads können den Service des Betreibers bewerten. Mit Abgabe einer Bewertung erteilen Leads dem Betreiber die Erlaubnis, die von ihm abgegebenen Bewertungen inklusive seines Namens im Internet auf dem Portal und auf Drittseiten wie z.B. Trustpilot zu veröffentlichen. Hierzu räumt der Nutzer dem Betreiber ein einfaches, unwiderrufliches, zeitlich, räumlich und inhaltlich unbeschränktes Nutzungsrecht an den Bewertungen zur Veröffentlichung dieser im Internet ein.</li>
  </ol>
  <h2>Haftung</h2>
  <ol>
    <li>Wir haften unbeschränkt, soweit die Schadensursache auf Vorsatz oder grober Fahrlässigkeit im Zusammenhang mit dem Betrieb der Plattform beruht. Wir haften auch für die leicht fahrlässige Verletzung von wesentlichen Pflichten (Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet) sowie für die Verletzung von Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut), jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Für die leicht fahrlässige Verletzung anderer als der vorstehenden Pflichten haften wir nicht.</li>
    <li>Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei der Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie, bei arglistig verschwiegenen Mängeln, bei einer Haftung nach dem Produkthaftungsgesetz und bei datenschutzrechtlichen Anspruchsgrundlagen.</li>
    <li>Ist unsere Haftung ausgeschlossen oder beschränkt, so gilt dies ebenfalls für die persönliche Haftung von Organen, Angestellten, Vertretern und Erfüllungsgehilfen.</li>
  </ol>
  <h2>Datenschutz</h2>
  <p>
    Hinsichtlich des Datenschutzes gelten die Datenschutzinformationen in der Datenschutzerklärung des Betreibers.
  </p>
  <h2>Salvatorische Klausel</h2>
  <p>
    Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.
  </p>
</v-container>
</template>

<script>
export default {
  name: "AGB"
}
</script>

<style scoped>
h2 {
  color: rebeccapurple;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}
li {
  margin-bottom: 1rem;
  text-align: justify;
}
</style>