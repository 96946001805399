import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from "../views/Login";
import Order from "../views/Order";
import UserManager from "@/views/UserManager";
import SimpleWasteDefinition from "@/views/SimpleWasteDefinition";
import AGB from "@/views/AGB";
import Home from "@/views/Home";
import OrderManager from "@/views/OrderManager";
import Dashboard from "@/views/Dashboard";
import Angebote from "@/views/Angebote";
import Anfragen from "@/views/Anfragen";
import Rechnungsverwaltung from "@/views/Rechnungsverwaltung";
import Profile from "@/views/Profile";
import Locationverwaltung from "@/views/Locationverwaltung";
import Benutzerverwaltung from "@/views/Benutzerverwaltung";
import UploadManager from "@/views/UploadManager";
import MediaManager from "@/views/MediaManager";
import Maptest from "@/views/Maptest";
import TestView from "@/views/TestView";
import Cms from "@/views/Cms";
import Company from "@/views/Company";
import EasyWasteDefinition from "@/views/EasyWasteDefinition";
import Intro from "@/views/Intro";
import Wastelist from "@/views/Wastelist";
import EDMBasic from "@/views/EDMBasic";
import {wasticsStore} from "@/store";
import LeadOverview from "@/views/LeadOverview";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/abfallliste',
    name: 'Wastelist',
    component: Wastelist
  },
  {
    path: '/welcome',
    name: 'Willkommen',
    component: Intro
  },
  {
    path: '/edm',
    name: 'EDM',
    component: EDMBasic
  },
  {
    path: '/entsorgen',
    name: 'Entsorgen',
    component: SimpleWasteDefinition
  },
  {
    path: '/easy-entsorgen',
    name: 'EinfachEntsorgen',
    component: EasyWasteDefinition
  },
  {
    path: '/home',
    name: 'Dashboard',
    component: Home
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/company',
    name: 'Company',
    component: Company
  },
  {
    path: '/leads',
    name: 'LeadOverview',
    component: LeadOverview
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locationverwaltung
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/orders',
    name: 'Auftragsübersicht',
    component: Order
  },
  {
    path: '/order-manager',
    name: 'OrderManager',
    component: OrderManager
  },
  {
    path: '/users',
    name: 'Benutzerverwaltung',
    component: UserManager
  },
  {
    path: '/uploads',
    name: 'Uploads',
    component: UploadManager
  },
  {
    path: '/media',
    name: 'MediaManager',
    component: MediaManager
  },
  {
    path: '/mappi',
    name: 'Maptest',
    component: Maptest
  },
    // Benutzerverwaltung von Sam
  {
    path: '/benutzer',
    name: 'Benutzer',
    component: Benutzerverwaltung
  },
  {
    path: '/testview',
    name: 'TestView',
    component: TestView
  },
  {
    path: '/registration',
    name: 'Registrierung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Registration.vue')
  },
  {
    path: '/agb',
    name: 'AGB',
    component: AGB
  },
    /* Demoday Samuel */
  {
    path: '/rV',
    name: 'Rechnungsverwaltung',
    component: Rechnungsverwaltung
  },
  {
    path: '/angebote',
    name: 'Angebote',
    component: Angebote
  },
  {
    path: '/anfragen',
    name: 'Anfragen',
    component: Anfragen
  },
  {
    path: '/cms',
    name: 'CMS',
    component: Cms
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  //return false
  const store = wasticsStore();
  if(store.loggedIn) {
    console.log("Yes, logged in")
  } else {
    console.log("No, not logged in")
  }
  console.log("To:")
  console.log(to)
  console.log("From:")
  console.log(from)
  console.log("Referrer:")
  console.log(document.referrer)
  //window.umami.trackView(to.path, null, '959954a6-dbb5-4f79-9dbc-e699c28decd1')
  if(from.name !== null) {
    setTimeout(()=>{
      window.umami.trackView(to.path, null, '959954a6-dbb5-4f79-9dbc-e699c28decd1')
      console.log("trackViewRouter")
    }, 500);
  }
  // window.umami.trackEvent('test-event', {}, to.path, '959954a6-dbb5-4f79-9dbc-e699c28decd1')
  next()
})

export default router


/* eslint-disable no-unused-vars */
/*
router.beforeEach((to, from, next) => {
  let payload = null;
  let payloadStorage = localStorage.getItem("wastics.payload");
  if(payloadStorage !== null) {
    let payload = JSON.parse(payloadStorage);
  }
  console.log(payload);
  if (
      // make sure the user is authenticated
      payload == null &&
      // ❗️ Avoid an infinite redirect
      to.name !== 'Login'
  ) {
    // redirect the user to the login page
    console.log("should return")
    console.log(to);
    next({ name: 'Login' });
  }
  else {
    next();
  }
}); */