<template>
<v-container>
  <v-row v-if="!loggedIn">
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-img
            src="https://source.unsplash.com/bI8df767i5o/w=600"
            gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
        >
          <v-card-title class="text--white" style="color: white;">Einzelauftrag</v-card-title>
        </v-img>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-img
            src="https://source.unsplash.com/OQMZwNd3ThU/w=600"
            gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
        >
          <v-card-title class="text--white" style="color: white;">Rahmenvertrag</v-card-title>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="loggedIn">
    <v-col cols="12">
      <v-card to="/anfragen">
        <v-card-title>Abfallmanagement</v-card-title>
        <v-sparkline
            :value="value"
            :gradient="gradient"
            :smooth="radius || false"
            :padding="padding"
            :line-width="width"
            :stroke-linecap="lineCap"
            :gradient-direction="gradientDirection"
            :fill="fill"
            :type="type"
            :auto-line-width="autoLineWidth"
            auto-draw
        ></v-sparkline>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="loggedIn && user.role == 'manager'">
    <v-col cols="12" sm="6">
      <v-card to="/order-manager">
        <v-card-title>Auftragsübersicht</v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card to="/users">
        <v-card-title>Benutzerverwaltung</v-card-title>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="loggedIn && user.role.startsWith('waste_creator')">
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-card-title>Einzelauftrag</v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-card-title>Rahmenvertrag</v-card-title>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="loggedIn && (user.role == 'waste_disposer' || user.role == 'waste_creator_disposer')">
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-card-title>Leads</v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card to="/entsorgen">
        <v-card-title>Stammdaten</v-card-title>
        <v-card-text>Bearbeite deine Stammdaten und Berechtigungen</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {mapState} from "pinia/dist/pinia";
import {wasticsStore} from "@/store";

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
]

export default {
  name: "Dashboard",
  data: () => ({
    dialog: false,
    //value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    gradient: gradients[3],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 3, 1, 8, 2, 7, 10],
    gradientDirection: 'top',
    gradients,
    fill: false,
    type: 'trend',
    autoLineWidth: false,
  }),
  computed: {
    currentWeek() {
      let now = new Date();
      let onejan = new Date(now.getFullYear(), 0, 1);
      let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
      return week;
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["user"]),
    ...mapState(wasticsStore, ["keycloak"]),
  },
}
</script>

<style scoped>

</style>