<template>
  <v-container>
    <v-card>
      <div id="mapContainer" style="height: 50vh; width: 100%;"></div>
      <v-progress-linear
          :indeterminate="loadCurrentPosition"
          color="primary"
          v-if="loadCurrentPosition"
      ></v-progress-linear>
      <v-subheader v-if="loadCurrentPosition">Aktueller Standort wird ermittelt.</v-subheader>
      <v-subheader v-else>Standort auf Karte wählen oder eingeben.</v-subheader>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="label" label="Bezeichnung" rounded filled></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey" v-bind="attrs" v-on="on" right class="middle">
                  mdi-information
                </v-icon>
              </template>
              <span>
                Unter dieser Bezeichnung wird dein Standort zusammenfassend gespeichert.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field v-model="street" label="Straße" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field label="Nummer" rounded filled v-model="streetNo"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field label="Tür" rounded filled v-model="door"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="zip" label="PLZ" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="city" label="Stadt" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select label="Land *" rounded filled v-model="country" :items="countries"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="lat" disabled outlined label="Lat"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="lng" disabled outlined label="Lng"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="getLocation" :disabled="loadCurrentPosition">Aktuelle Position</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="parentCancel" >Abbrechen</v-btn>
        <v-btn @click="getLatLon" color="purple lighten-2" dark>Suchen</v-btn>
        <v-btn @click="parentClick" color="primary">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {Icon} from "leaflet";
import L from "leaflet";
import axios from "axios";
import {mapActions} from "pinia/dist/pinia.esm-browser";
import {wasticsStore} from "@/store";
import {mapState} from "pinia/dist/pinia";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "LocationMap",
  data: () => ({
    test: false,
    location: [48.1767414, 16.3313324],
    map: null,
    label: "",
    street: "",
    streetNo: "",
    door: "",
    city: "",
    zip: "",
    country: "",
    countries: ["Österreich", "Deutschland", "Schweiz"],
    marker: null,
    lat: 48.1767414,
    lng: 16.3313324,
    position: null,
    loadCurrentPosition: true,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    logLayers() {
      this.map.eachLayer((layer) => {
        console.log(layer);
        if(layer._icon) {
          layer.remove();
        }
      });
    },
    setupLeaflet() {
      this.map = L.map("mapContainer").setView(this.location, 18);
      L.tileLayer(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            attribution: 'Wastics',
            maxZoom: 19,
          }
      ).addTo(this.map);
      this.map.on('click', this.onMapClick);
      setTimeout(()=>{
        this.map.invalidateSize();
      }, 500);
    },
    setMarker() {
      if(this.marker != null) {
        this.marker.remove();
      }
      this.marker = L.marker(this.location).addTo(this.map);
    },
    getLatLon() {
      axios.get("https://nominatim.openstreetmap.org/search?format=json&street="+ encodeURIComponent(this.street + " " + this.streetNo) + "&city=" + encodeURIComponent(this.city) + "&postalcode=" + encodeURIComponent(this.zip))
          .then((res)=>{
            console.log(res.data);
            this.location = [];
            this.location.push(res.data[0].lat);
            this.location.push(res.data[0].lon);
            this.lat = res.data[0].lat;
            this.lng = res.data[0].lon;
            if(this.marker != null) {
              this.marker.remove();
            }
            this.setMarker();
            this.map.setView(this.location, 18);
          })
    },
    onMapClick(e) {
      console.log(e.latlng);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + e.latlng.lat + "&lon=" + e.latlng.lng + "&zoom=18&addressdetails=1")
          .then((res)=>{
            console.log(res.data);
            if(res.data.address.city) {
              this.city = res.data.address.city;
            } else {
              if(res.data.address.town) {
                this.city = res.data.address.town;
              } else {
                this.city = res.data.address.state;
              }
            }
            if(res.data.address.postcode) {
              this.zip = res.data.address.postcode;
            }
            if (res.data.address.road) {
              this.street = res.data.address.road;
            }
            if (res.data.address.house_number) {
              this.streetNo = res.data.address.house_number;
            }
            if (res.data.address.country) {
              this.country = res.data.address.country;
            }
            this.location = [];
            this.location.push(e.latlng.lat);
            this.location.push(e.latlng.lng);
            this.lat = e.latlng.lat;
            this.lng = e.latlng.lng;
            if(this.marker != null) {
              this.marker.remove();
            }
            this.setMarker();
          })
    },
    getLocation() {
      this.loadCurrentPosition = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.savePosition, this.positionError, {timeout:10000});
      } else {
        console.log("not supported gls");
      }
    },
    positionError(error) {
      console.log(error.code);
      console.log(error.message);
      this.loadCurrentPosition = false;
    },
    savePosition(position) {
      console.log(position);
      this.position = position;
      this.location = [];
      this.location.push(position.coords.latitude);
      this.location.push(position.coords.longitude);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.setMarker();
      this.map.setView(this.location, 18);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + this.lat + "&lon=" + this.lng + "&zoom=18&addressdetails=1")
          .then((res)=> {
            console.log(res.data);
            if (res.data.address.city) {
              this.city = res.data.address.city;
            } else {
              if (res.data.address.town) {
                this.city = res.data.address.town;
              } else {
                this.city = res.data.address.state;
              }
            }
            if(res.data.address.postcode) {
              this.zip = res.data.address.postcode;
            }
            if (res.data.address.road) {
              this.street = res.data.address.road;
            }
            if (res.data.address.house_number) {
              this.streetNo = res.data.address.house_number;
            }
            if (res.data.address.country) {
              this.country = res.data.address.country;
            }
            this.label = this.street + " " + this.streetNo + ", " + this.city;
          });
      this.setMap(this.map);
      this.loadCurrentPosition = false;
    },
    parentCancel() {
      let result = {
        action: "cancel"
      };
      this.$emit('clicked', result);
    },
    parentClick() {
      let data = {
        label: this.label,
        street: this.street,
        streetNo: this.streetNo,
        door: this.door,
        zip: this.zip,
        city: this.city,
        country: this.country,
      };
      let result = {
        action: "save",
        data: data,
      };
      this.$emit('clicked', result);
    },
    ...mapActions(wasticsStore, ["setMap"]),
  },
  mounted() {
    this.setupLeaflet();
    this.getLocation();
    console.log("LocationMap mounted")
  },
  updated() {
    console.log("LocationMap updated")
  }
}
</script>

<style>
.leaflet-control-attribution {
  display: none !important;
}
</style>